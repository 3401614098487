<template>
  <div :class="[fade_class,'page']">
    <img :src="product_banner" style="width: 100vw;" />
    <div style="width: 75vw;margin: 80px auto 0;">
      <div class="product_type product_erpimg">
        <div class="type_title">ERP+MES系统</div>
        <div class="type_desc">背靠台湾专业鲜食工厂团队和管理标准、依托日本先进鲜食制造技术设计的ERP+MES系统，从原料采购到生产出库再到财务结算和数据分析，提供全流程信息化服务。</div>
      </div>
      <div class="product_type product_appimg">
        <div class="type_title">移动APP</div>
        <div class="type_desc">为武汉公交集团、郑州公交集团学生餐等客户场景定制线上订餐和增值服务类APP，通过不断升级迭代，为数万名企业用户提供智能化服务。</div>
      </div>
      <div class="product_type product_appletimg">
        <div class="type_title">小程序</div>
        <div class="type_desc">提供电商、应用、本地生活等各类小程序开发服务，经验丰富的产品&技术团队，专注用户体验累计服务C端用户10万+。</div>
      </div>
    </div>
    <img :src="product_title_img" style="margin: 0 auto;display: block;width: 16.56vw;" />
    <div style="width: 75vw;border-bottom: 1px solid #dde2ee;margin: 0 auto;text-align: center;">
      <div v-for="item in products" :key="item" @click="changeProduct(item)" :class="['product',item.class]">{{item.name}}</div>
    </div>
    <div :class="['product_box',fade_product]">
      <img :src="productImg" class="product_img" />
      <div class="product_detail">
        <div class="product_title">{{product_title}}</div>
        <div class="product_subtitle">{{product_subtitle}}</div>
        <div class="product_desc">
          <div v-for="desc in product_desc" :key="desc">{{desc}}</div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'products-',
  props: {
    fade_class: String
  },
  data () {
    return {
      product_banner: require('@/assets/product_banner.png'),
      product_title_img: require('@/assets/product_title.png'),
      products: [
        {id: 0,name: 'ERP+MES系统',img: require('@/assets/product_img1.png'),title: 'ERP+MES系统',subtitle: '企业内部生产信息管理系统',desc: ['背靠台湾专业鲜食工厂团队和管理标准、依托日本先进鲜食制造技术设计的ERP+MES系统，从原料采购到生产出库再到财务结算和数据分析，提供全流程信息化服务。','ERP生产管理系统，建立了一体化操作的体系，实现一个平台兼容所有数据”、“一次录入实现全面应用”，彻底解决生产过程中不同阶段的管理需求。','闭环ERP+MES生产管理系统，既能避免多套系统切换多套软件并行、多次数据录入的繁琐操作，又能实现业务流程、信息数据、财务账目结算等的无缝集成，全面满足生产管理之所需。'],class: 'active' },
        {id: 1,name: '美膳APP',img: require('@/assets/product_img2.png'),title: '美膳APP',subtitle: '武汉公交线上点餐系统',desc: ['“美膳”是一款专门为武汉公交集团定制的在线订餐系统。依托武汉公交集团+供应商，结合移动互联网 +大数据技术，以服务武汉公交集团员工用餐为核心宗旨系统自2017年7月上线以来，结合实际场景需求和用户体验，不断升级优化，已经成为简洁美观、操作便捷数据准确、稳定性高的企业团膳类在线订餐服务平台']},
        {id: 2,name: '公交餐饮APP',img: require('@/assets/product_img3.png'),title: '公交餐饮APP',subtitle: '郑州公交线上点餐系统',desc: ['“与郑州公交餐饮服务有限公司合作，开发的一款为郑州公交员工提供在线订餐、各站点送水服务的APP。根据郑州公交餐饮公司提供的菜品信息上架到app，员工在线预订工作餐，简单快捷。同时各个站点可在线预约送水服务预约后由水厂、水站、物流车根据需求完成订单履约”','2022年3月上线至今，面向郑州公交集团，已向1万多名一线员工全面开放订餐，提供线上点餐的系统支持。']},
        {id: 3,name: '美膳校园',img: require('@/assets/product_img6.png'),title: '美膳校园',subtitle: '学生餐点餐系统',desc: ['“美膳校园”是一款专为校园学生餐家长定制的在线点餐app，每个学生家长注册一个账号和学生姓名关联，由家长为学生点餐，服务商通过点餐数据配餐，可提前阅览一周菜单，反馈就餐情况。']},
        {id: 4,name: '中百团膳',img: require('@/assets/product_img4.png'),title: '中百团膳',subtitle: '微信线上团餐+团购小程序',desc: ['为武汉本地企业单位等提供大、小型线上预定团餐送货上门服务，同时开通200多家线下门店的团购自提业务模式。'],},
        {id: 5,name: '唐肴鲜生',img: require('@/assets/product_img5.png'),title: '唐肴鲜生',subtitle: '请货平台',desc: ['“唐肴鲜生”是一个线上供应链请货平台，为中央厨房提供门店客户线上供应链请货+结算服务。'],}
      ],
      productIdx: 0,
      productImg: require('@/assets/product_img1.png'),
      fade_product: 'fade_in',
      product_title: 'ERP+MES系统',
      product_subtitle: '企业内部生产信息管理系统',
      product_desc: ['背靠台湾专业鲜食工厂团队和管理标准、依托日本先进鲜食制造技术设计的ERP+MES系统，从原料采购到生产出库再到财务结算和数据分析，提供全流程信息化服务。','ERP生产管理系统，建立了一体化操作的体系，实现一个平台兼容所有数据”、“一次录入实现全面应用”，彻底解决生产过程中不同阶段的管理需求。','闭环ERP+MES生产管理系统，既能避免多套系统切换多套软件并行、多次数据录入的繁琐操作，又能实现业务流程、信息数据、财务账目结算等的无缝集成，全面满足生产管理之所需。']
    }
  },
  methods: {
    changeProduct(e){
      this.products[this.productIdx].class = ''
      this.products[e.id].class = 'active'
      this.productIdx = e.id
      let _this = this
      this.fade_product = 'fade_out'
      setTimeout(() => {
        _this.productImg = e.img
        _this.product_title = e.title
        _this.product_subtitle = e.subtitle
        _this.product_desc = e.desc
        _this.fade_product = 'fade_in'
      },200)
    }
  }
}
</script>

<style scoped>
  .product_type{
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    width: 23.96vw;
    border: 1px solid #ecebeb;
    margin-left: 1.56vw;
    padding: 12px;
    padding-right: 12vw;
    background-repeat: no-repeat;
    background-position: right 10px center;
    height: 14vw;
    transition: all ease .1s;
  }
  .product_type:hover{
    transform: scale(1.05);
    box-shadow: 0 0px 5px rgba(0, 0, 0, .1);
  }
  .product_type:nth-child(1){
    margin-left: 0;
  }
  .type_title{
    font-family: "Microsoft YaHei";
    color: #3a3a3a;
    font-size: 1.56vw;
  }
  .type_desc{
    font-family: "Microsoft YaHei";
    color: #3a3a3a;
    font-size: 0.83vw;
    line-height: 1.5vw;
    margin-top: 12px;
  }
  .product_erpimg{
    background-image: url('@/assets/product_erpimg.png');
    background-size: 10.36vw 9.84vw;
  }
  .product_appimg{
    background-image: url('@/assets/product_appimg.png');
    background-size: 10.57vw 7.34vw;
  }
  .product_appletimg{
    background-image: url('@/assets/product_appletimg.png');
    background-size: 10.52vw 7.81vw;
  }
  .product{
    display: inline-block;
    color: #b2b2b2;
    font-size: 1.25vw;
    padding: 1vw 2.5vw;
    position: relative;
    cursor: pointer;
    transition: all ease .3s;
  }
  .product:hover{
    color: #fff !important;
    background-color: #5688ff;
  }
  .product.active{
    color: #5688ff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .3);
  }
  .product.active::after{
    content: '';
    width: 100%;
    position: absolute;
    height: 3px;
    background-color: #5688ff;
    bottom: -2px;
    left: 0;
  }
  .product_box{
    display: block;
    width: 75vw;
    margin: 4vw auto;
    transition: all ease .2s;
    opacity: 0;
    background-color: #ebf4fd;
  }
  .product_box.fade_in{
    opacity: 1;
  }
  .product_box.fade_out{
    opacity: 0;
  }
  .product_img{
    width: 48vw;
    display: inline-block;
    vertical-align: top;
  }
  .product_detail{
    width: 27vw;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 2vw;
  }
  .product_title{
    font-size: 1.25vw;
    color: #5688ff;
    font-family: "Microsoft YaHei";
    text-align: center;
    margin-top: 1.5vw;
  }
  .product_subtitle{
    font-family: "Microsoft YaHei";
    font-size: .94vw;
    color: #333;
    text-align: center;
    margin-top: .5vw;
  }
  .product_desc{
    font-family: "Microsoft YaHei";
    margin-top: 1.5vw;
    color: #333;
    font-size: .83vw;
    text-indent: 1rem;
    line-height: 1.5vw;
  }
</style>
