<template>
  <div>
    <img
        style="width: 100%;"
        :src="require('@/assets/qyzz/qualifications_banner.png')"
        fit="fill"/>
    <div style="margin-top: 100px;" class="clearfix">
      <img :src="require('@/assets/qyzz/qualifications_title1.png')"   class="companyImg" >
      <div class="companyMemoTitle" >
        <span class="conpanyBlue">武汉美膳亿禾科技有限公司</span>
        <span class="companyMemo">是一家集互联网软件平台项目开发和运营为一体的信息科技公司。企业通过推进“平台、技术、云服务”这三大核心板块，服务包括湖北农发鲜科集团、中百集团、郑州公交餐饮公司等多家大型上市和集团企业以及数十万个人客户，提供包括团生产管理ERP+MES系统、团膳订餐平台、个人订餐平台、供应链系统在内的全方位科技信息服务。致力于成为全国领先的食品加工制造行业SAAS软件系统服务商。</span>
      </div>
    </div>
    <div style="margin-top: 100px;" class="clearfix">
      <img :src="require('@/assets/qyzz/qualifications_title2.png')"   class="companyImg" >
      <div class="companyMemoTitle" >
        <span class="conpanyBlue">服务为先，技术为本</span>
        <span class="companyMemo">是我们公司的企业文化，我司专注于移动互联网和食品加工SAAS，坚持以技术创新为核心，以用户需求为导向，关注用户体验，市场发展方向，为企业客户提供最专业的移动应用和SAAS产品的解决方案。</span>
      </div>
    </div>
    <div style="margin-top: 100px;" class="clearfix">
      <img :src="require('@/assets/qyzz/qualifications_title3.png')"   class="companyImg" >
      <div class="companyMemoTitle" >
        <span class="conpanyBlue">知识产权-软件著作权: </span>
        <span class="companyMemo">食品加工制造ERP管理平台、公交餐饮订餐系统平台、美膳公交订餐系统平台、中百团膳订餐系统、唐肴鲜生平台管理系统、古唐小店平台管理系统。</span>
      </div>
    </div>

    <div class="zizhiArea clearfix">
<!--      <div style="margin:0px auto;width: 21vw;height: 27vw">    <img :src="require('@/assets/qyzz/qualifications_img1.png')"   class="zizhiImg" ></div>-->
<!--      <div class="zhengshuMemo" style="margin-top: 38px">电信增值业务许可证icp证书</div>-->
      <div>
        <div class="clearfix">
          <div class="zhengshuImg">
            <div > <img :src="require('@/assets/qyzz/qualifications_img1.png')"   class="zizhiImg" ></div>
            <div class="zhengshuMemo">ERP系统</div>
          </div>
          <div class="zhengshuImg">
            <div > <img :src="require('@/assets/qyzz/qualifications_img2.png')"   class="zizhiImg" ></div>
            <div class="zhengshuMemo">公交餐饮APP</div>
          </div>
          <div class="zhengshuImg">
            <div > <img :src="require('@/assets/qyzz/qualifications_img3.png')"   class="zizhiImg" ></div>
            <div class="zhengshuMemo">美膳APP</div>
          </div>
        </div>

        <div class="clearfix">
          <div class="zhengshuImg">
            <div > <img :src="require('@/assets/qyzz/qualifications_img4.png')"   class="zizhiImg" ></div>
            <div class="zhengshuMemo">唐肴鲜生小程序</div>
          </div>
          <div class="zhengshuImg">
            <div > <img :src="require('@/assets/qyzz/qualifications_img5.png')"   class="zizhiImg" ></div>
            <div class="zhengshuMemo">中百团膳小程序</div>
          </div>
          <div class="zhengshuImg">
            <div > <img :src="require('@/assets/qyzz/qualifications_img6.png')"   class="zizhiImg" ></div>
            <div class="zhengshuMemo">古唐小店小程序</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'company-',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.companyImg{
  width:180px ;
  height:55px ;
  float: left;
  margin-left: 15%;
}
.companyMemoTitle{
  width: 700px;
  float: left;
  margin-left: 70px;
}
.companyMemo{
  vertical-align: top;
  font-size: 20px;
  color: #666666;
}
.conpanyBlue{
  vertical-align: top;
  font-size: 20px;
  color: #4378f5;
}

.clearfix:after {
  content: "";
  display: block;
  line-height: 0;
  visibility: hidden;
  height: 0;
  clear: both;
}
.zizhiArea{
  background: #f3f6fd;
  margin-top: 80px;
  margin-left: 10%;
  margin-right: 15%;
}
.zizhiImg{
  margin-top: 25px;
  width:21vw ;
  height:27vw ;
}
.zhengshuImg{
  width: 21vw;
  margin-left: 46px;
  float: left
}
.zhengshuMemo{
  text-align: center;
  margin:20px auto;
  color: #666666;
  font-size: 20px;
}
</style>
